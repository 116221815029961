




import { Component, Vue } from 'vue-property-decorator';
import TaskList from '@/views/data-manage/task-list.vue';
@Component({
  name: 'ArchiveFlow',
  components: { TaskList }
})
export default class ArchiveFlow extends Vue {}
